/**
 * 超鹿的自定义提示
 * 拓展 Vant的toast 三个方法 Toast.text ， Toast.bigImg， Toast.smallImg
 * 用法与Vant的toast相同，只是将方法名Toast换成那三个即可，例如： this.$toast.text('hahahaha')
 * 注意：不要传入type类型
 */

/**
 * todo
 * 图片加载展示有延迟，可以设置字体图库先加载优化，
 * 也可以在应用页面先加载图片预先缓存
 */

import { Toast } from 'vant'
import './toast.less'

// 对象判断
function isObject(val) {
  return val !== null && typeof val === 'object';
}
// 字符串转obj， obj 直接返回
function parseOptions(message) {
  if (isObject(message)) {
    return message;
  }
  return {
    message: message
  };
}

function optionCheck(options, messageLength, method) {
  if (options.message.length > messageLength) {
    console.error(`${method}方法 传入的message必须小于${messageLength}个字符`)
    return false
  }
  if (options.type) {
    console.error(`${method}方法,不要传入type类型，避免不必要的错误，如过必要，可以直接使用toast方法自定义`)
    return false
  }
  return true
}
// Toast.setDefaultOptions({
//     className: 'cl-toast-default',
//     // iconPrefix: 'cl-toast-icon'
// })
// 纯文字提示
Toast.text = function (options) {
  // 格式验证
  options = parseOptions(options)
  // 字符串长度检测
  let hasPass = optionCheck(options, 24, 'Toast.text')
  // 强制html类型text
  options.type = 'text'
  // classname设置
  options.className = options.className ? 'cl-toast-default ' + options.className : 'cl-toast-default'
  return Toast(options)
}

// 大图提示
Toast.bigImg = function (options) {
  // 设置默认图标
  let defaultOption = { icon: 'like-o' }
  // 格式验证
  options = parseOptions(options)
  // 字符串长度检测
  let hasPass = optionCheck(options, 12, 'Toast.bigImg')
  // 强制html类型
  options.type = 'html'
  // classname设置
  options.className = options.className ? 'cl-toast-default ' + options.className : 'cl-toast-default'
  // message转html
  let _message = options.message
  options.message = `<p class="cl-toast-big-img-text">${_message}</p>`
  // 合并默认选项
  let _options = Object.assign(defaultOption, options)

  return Toast(_options)
}

// 小图提示
Toast.smallImg = function (options) {
  // 设置默认图标
  let defaultOption = { icon: 'like-o' }
  options = parseOptions(options)
  // 字符串长度检测
  let hasPass = optionCheck(options, 12, 'Toast.smallImg')
  // 强制html类型
  options.type = 'html'
  // classname设置
  options.className = options.className ? 'van-toast--html cl-toast-small-img' + options.className : 'van-toast--html cl-toast-small-img'
  // message转html
  let _message = options.message
  options.message = `<p class="cl-toast-small-img-text">${_message}</p>`
  // 合并默认选项
  let _options = Object.assign(defaultOption, options)
  // _options.icon=''
  return Toast(_options)
}

export default Toast