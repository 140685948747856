import wx from 'weixin-js-sdk';
export default function beforeRouterAction(to, from, next){
  // if(to.path=='/'){
  //   wx.miniProgram.redirectTo({url:'/pages/webView/index?webUrl=https://web.chaolu.com.cn/#/superdeer-activity/spring-awakening-2022/redEnvelope&userId=1&token=1'})
  //   // next('/superdeer-activity/spring-awakening-2022/redEnvelope');
  //   return;
  // }
   //假设当前是首页，一会判断
  // 先判断当前是否有上一页，预防刷新页面
  let result = "";
  if (from.name) {
    result = {
      // 当前页
      currentPage: to.name,
      currentPageQuery: to.query,
      currentPageParams: to.params,
      // 上一页
      lastPage: from.name,
      lastPageQuery: from.query,
      lastPageParams: from.params
    };
    sessionStorage.setItem("pageLink", JSON.stringify(result));
  } else {
    // 刷新页面或者首页
    if (to.name == "home") {
      // 首页
      result = {
        // 当前页
        currentPage: to.name,
        currentPageQuery: to.query,
        currentPageParams: to.params,
        // 上一页
        lastPage: from.name,
        lastPageQuery: from.query,
        lastPageParams: from.params
      };
      sessionStorage.setItem("pageLink", JSON.stringify(result));
    } else {
      // 刷新页面,不做任何操作
    }
  }
  // window.scrollTo(0, 0);
  let token = sessionStorage.getItem("m_token");
  if (to.meta.requireAuth) {
    if (!token) {
      next({
        path: "/login",
        query: {
          redirect: to.fullPath,
          time: new Date().getTime()
        }
      });
    } else {
      next();
    }
  } else {
    next();
  }
  // 记录当前路由名
  sessionStorage.setItem("routerName", to.name);
  // 记录当前路由头部信息
  let headerType = to.meta.headerType?to.meta.headerType:1;
  sessionStorage.setItem("currentPageHeaderType",headerType)
}
