/**
 * 作用：通用方法
 */
import { hideAppBackBtn, hideBar, showBar, getBarHeight, appType } from './appMethod'

// html 头部适配
export function htmlHeaderAdapt() {
    let headerHeight = $(".app-header").height();
    $('.app-page-main').css({
        "padding-top": `${headerHeight}px`,
        'height': `calc(100vh - ${headerHeight}px)`,
        '-webkit-overflow-scrolling': 'touch',
        'overflow-y': 'auto'
    })
}


// app适配
export function appHeaderAdapt() {
    let u = navigator.userAgent;
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    let $headerEle = $(".app-header");
    let appHeight = $headerEle.height();
    let headerHeight = 0;
    if (isIOS) {
        if (screen.height == 812 && screen.width == 375 || screen.height == 896 && screen.width == 414) {
            headerHeight = appHeight + 44;
        } else {
            headerHeight = appHeight + 22;
            $headerEle.css({
                "padding": "20px 0 2px 0"
            });
        }
    } else {
        try {
            showBar();
        } catch (e) {
            console.log('showBar')
        }

        headerHeight = appHeight
    }
    $('.app-page-main').css({
        "padding-top": `${headerHeight}px`,
        'height': `calc(100vh - ${headerHeight}px)`,
        '-webkit-overflow-scrolling': 'touch',
        'overflow-y': 'auto'
    })
    return headerHeight
}

// 隐藏隐藏返回键
export function hideBackBtnNav() {
    let u = navigator.userAgent;
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    let $headerEle = $(".app-header");
    let appHeight = $headerEle.height();
    let headerHeight = 0;

    try {
        hideAppBackBtn()
        if (isIOS) {
            let paddingHeight = 20;
            if (screen.height == 812 && screen.width == 375 || screen.height == 896 && screen.width == 414) {
                paddingHeight = 44;
            }
            headerHeight = appHeight + paddingHeight;
            $headerEle.css({
                "padding": `${paddingHeight}px 0 0 0`
            });
        } else {
            showBar()
            headerHeight = appHeight
        }
    } catch (error) {
        headerHeight = appHeight;
    }
    $('.app-page-main').css({
        "padding-top": `${headerHeight}px`,
        'height': `calc(100vh - ${headerHeight}px)`,
        '-webkit-overflow-scrolling': 'touch',
        'overflow-y': 'auto'
    })
}
/**
 * 功能：根据headerType初始化页面,处理页面头部适配和页面高度
 * @param {*} headerType
 */
export function initPage(headerType) {
    headerType = headerType + '';
    switch (headerType) {
        case '1':
            // h5带头适配
            htmlHeaderAdapt()
            break
        case '2':
            // app原生带头适配,该方法暂时废弃
            appHeaderAdapt()
            break
        case '3':
            // app原生隐藏头部适配
            hideBackBtnNav()
            break
        case '4':
            // app沉浸式适配
            immersiveHeaderAdapt()
            // hideBackBtnNav()
            break
        case '5': {
            // 隐藏app头部
            try {
                hideAppBackBtn();
                hideBar();
            } catch (error) {

            }

            break
        }
        // 其它适配
        default:
            break
    }
}

/**
 * 功能：沉浸式适配
 */
export function immersiveHeaderAdapt() {
    // let u = navigator.userAgent;
    // let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    let appTypeStr = appType()
    let $headerEle = $(".app-header");
    let appHeight = $headerEle.height();
    let headerHeight = 0;

    try {
        hideAppBackBtn()
        // console.log('app环境')
        if (appTypeStr === 'ios') {
            let paddingHeight = 20;
            if (screen.height == 812 && screen.width == 375 || screen.height == 896 && screen.width == 414) {
                paddingHeight = 44;
            }
            headerHeight = appHeight + paddingHeight;
            $headerEle.css({
                "padding": `${paddingHeight}px 0 0 0`
            });
        } else if (appTypeStr === 'and') {
            hideBar()
            // 安卓适配
            headerHeight = appHeight + getBarHeight();
            $headerEle.css({
                "padding-top": getBarHeight() + "px"
            });
        }
    } catch (error) {
        // console.log('浏览器环境')
        headerHeight = appHeight;
    }
    $('.app-page-main').css({
        'height': `100vh`,
        '-webkit-overflow-scrolling': 'touch',
        'overflow-y': 'auto'
    })
}

// 获取当前时间 ' yyyy-mm-dd HH:mm:ss '
export function getFormatDate(time, fmt = 'yyyy-MM-dd') {
    var date = new Date(time);
    var o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        "S": date.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    }
    return fmt;
}
// 获取当前时间 ' yyyy-mm-dd HH:mm:ss '
export function getNowFormatDate() {
    var date = new Date();
    var seperator1 = "-";
    var seperator2 = ":";
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    var hour = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
    }
    if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
    }
    if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
    }
    var currentdate =
        date.getFullYear() +
        seperator1 +
        month +
        seperator1 +
        strDate +
        " " +
        hour +
        seperator2 +
        minutes +
        seperator2 +
        seconds;
    return currentdate;
}

// 获取某月份到今天的所有月份（list.reverse();//数组倒序）
export function getMiddleMonth() {
    var startTime = "2018-1"; //开始时间 不要加0，如2017-01
    var now = new Date();
    var arr = startTime.split("-");
    var startYear = arr[0]; //开始年份
    var startMonth = arr[1]; //开始月份
    var month = now.getMonth() + 1; //现在的月份
    var year = now.getFullYear(); //现在的年份
    var count = (year - startYear) * 12 + month - startMonth + 1; //循环的次数
    var data = [];
    for (var i = 0; i < count; i++) {
        if (startMonth < 10) {
            startMonth = "0" + startMonth;
        }
        data[i] = startYear + "-" + startMonth;
        if (startMonth == 12) {
            startYear++;
            startMonth = 0;
        }
        startMonth++;
    }
    return data.reverse();
}
// 乘法函数
export function accMul(arg1, arg2) {
    var m = 0,
        s1 = arg1.toString(),
        s2 = arg2.toString();
    try {
        m += s1.split(".")[1].length;
    } catch (e) {
        console.log(e)
    }
    try {
        m += s2.split(".")[1].length;
    } catch (e) {
        console.log(e)
    }
    return (
        (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
        Math.pow(10, m)
    );
}
// 减法函数
export function subtr(arg1, arg2) {
    var r1, r2, m, n;
    try {
        r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
        r1 = 0;
    }
    try {
        r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
        r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    n = r1 >= r2 ? r1 : r2;
    return ((arg1 * m - arg2 * m) / m).toFixed(n);
}
// 加法函数
export function accAdd(arg1, arg2) {
    var r1, r2, m;
    try {
        r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
        r1 = 0;
    }
    try {
        r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
        r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    return (arg1 * m + arg2 * m) / m;
}

// 生成一个36位的UUID
export function getUUID() {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4";
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
    s[8] = s[13] = s[18] = s[23] = "-";
    var uuid = s.join("");
    return uuid;
}

// 生成6位数字的随机验证码
export function getChenkNum() {
    var checkednum = "";
    for (var i = 0; i < 6; i++) {
        checkednum = checkednum + Math.floor(Math.random() * 10);
    }
    return checkednum;
}
// 计算参数的px
export function getPX(data) {
    var _data = Number(data)
    if (_data) {
        var _w = window.document.documentElement.clientWidth || 750;
        // _w = _w>750? 750 : _w
        return (_data / 750) * _w;
    } else {
        return data;
    }
}

//url获取参数
export function getParam(url = window.location.href) {
    var urlParam = url.split('?')[1]
    if (!urlParam) {
        return {}
    }
    var paramArr = urlParam.split("&")
    var param = {}
    for (var i = 0; i < paramArr.length; i++) {
        var item = paramArr[i].split('=')
        param[item[0]] = item[1]
    }
    return param
}

// 防抖、节流
export function throttle(fn, interval) {
    var timer;
    var enterTime = 0;//触发的时间
    var gapTime = interval || 300;//间隔时间，如果interval不传，则默认300ms
    return function () {
        clearTimeout(timer);
        var context = this;
        var args = arguments;//保存此处的arguments，因为setTimeout是全局的，arguments不是防抖函数需要的。
        var backTime = new Date();//第一次函数return即触发的时间
        if (backTime - enterTime > gapTime) {
            fn.call(context, arguments);
            enterTime = backTime;//赋值给第一次触发的时间，这样就保存了第二次触发的时间
        }
        timer = setTimeout(function () {
            fn.call(context, args);
        }, gapTime);
    };
}

/**
 *
 * @param v1  当前版本
 * @param v2  要比较的版本
 * @returns {number}
 */
export function compareVersion(v1, v2) {
    if (!v1 || !v2) return 1
    v1 = v1.split('.')
    v2 = v2.split('.')
    const len = Math.max(v1.length, v2.length)

    while (v1.length < len) {
        v1.push('0')
    }
    while (v2.length < len) {
        v2.push('0')
    }

    for (let i = 0; i < len; i++) {
        const num1 = parseInt(v1[i])
        const num2 = parseInt(v2[i])

        if (num1 > num2) {
            return 1
        } else if (num1 < num2) {
            return -1
        }
    }
    return 0
}

//计算时间差
export function getTimeDiff(start, end) {
    let cha;
    if (typeof (start) == "object") {
        cha = (
            new Date(getFormatDate(end, 'yyyy/MM/dd hh:mm')).getTime()
            -
            new Date(getFormatDate(start, 'yyyy/MM/dd hh:mm')).getTime()
        ) / 1000;
    } else {
        cha = (new Date(end.replace(/(-)/g, '/')).getTime() - new Date(start.replace(/(-)/g, '/')).getTime()) / 1000;
    }
    if (cha <= 0) {
        return null;
    }
    const d = Math.floor(cha / 60 / 60 / 24);
    const h = Math.floor(cha / 60 / 60 % 24);
    const m = Math.floor(cha / 60 % 60);
    const s = Math.floor(cha % 60);
    return { d, h, m, s }
}
export function isWeixin() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
    } else {
        return false;
    }
}
export function isPcWx() {
    //判断当前入口是PC端还是APP端
    let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    console.log('flag', flag)
    return !flag && isWeixin();
    // return true;
}
export function isEmpty(data) {
    // console.log(typeof data)
    switch (typeof data) {
        case "number":
            return data === 0
        case "object":
            return (!data || Object.keys(data).length < 1);
        case "undefined":
            return true
        case "boolean":
            return false
        case "string":
            return (data === 'undefined' || data === 'null' || data === '' || data === '0')
    }
}

/**
 * 获取最近月份时间
 * @param num  获取最近几月的时间
 * @param fmt  时间格式
 * @returns {array}
 */
export function getLastMonth(num = 6, fmt = 'yyyy年MM月') {
    let nowDate = new Date(),
        year = nowDate.getFullYear(),
        month = nowDate.getMonth(),
        arr = []
    for (let i = 0; i < num; i++) {
        let date = new Date(year, month - i, 1)
        arr.push({
            text: i === 0 ? '本月' : getFormatDate(date, fmt),
            text1: i === 0 ? '本月' : getFormatDate(date, 'M月'),
            value: getFormatDate(date, 'yyyy-MM')
        })
    }
    return arr
}

/**
 * 获取指定月份开始和结束日期
 * @param date
 * @param fmt
 * @returns {{endDate, startDate}}
 */
export function getMonthStartAndEnd(date = new Date(), fmt = 'yyyy-MM-dd') {
    let dateObj = toDate(date)
    let y = dateObj.getFullYear();
    let m = dateObj.getMonth();
    let start = new Date(y, m, 1); // 得到当月第一天
    let end = new Date(y, m + 1, 0); // 得到当月最后一天
    return {
        startDate: getFormatDate(start, fmt),
        endDate: getFormatDate(end, fmt),
    }
}

export function toDate(v) {
    if (!v) {
        return new Date()
    }
    if (typeof v === 'string') {
        return new Date(v.replace(/-/g, "/"))
    } else if (typeof v === 'number') {
        return new Date(v);
    } else if (typeof v === 'object') {
        return v
    }
}

// 计算月份之间差了几个月
// new Date('2023-05-01')
export function getMonthRange(minDate, maxDate) {
    if (minDate && maxDate) {
        let minYear = minDate.getFullYear();
        let minMonth = minDate.getMonth() + 1;
        let maxYear = maxDate.getFullYear();
        let maxMonth = maxDate.getMonth() + 1;
        return (maxYear * 12 + maxMonth) - (minYear * 12 + minMonth)
    } else {
        return ''
    }
  }

export function getRedString(string, place, className = 'red') {
    return string.replaceAll(place, "<span class=" + className + ">" + place + "</span>");
}
