export default [
    {
        "path": "/coach-own-member-coupon",
        "name": "coach-own-member-coupon",
        "meta": { "title": "领取教练专属优惠券","headerType": 5},
        component: () => import('../views/coach-own-member/coupon')
    },
    {
        "path": "/invite-gift",
        "name": "invite-gift",
        "meta": { "title": "邀请有礼","headerType": 5},
        component: () => import('../views/invite-gift/index.vue')
    },
    {
        "path": "/superdeer-activity/private-invite-202307/index",
        "name": "superdeer-activity/private-invite-202307/index",
        "meta": { "title": "邀新上私教，赢限定礼","headerType": 5},
        component: () => import('../views/superdeer-activity/private-invite-202307/index.vue')
    },
    {
        "path": "/superdeer-activity/private-invite-202307/receive",
        "name": "superdeer-activity/private-invite-202307/receive",
        "meta": { "title": "奖品领取确认","headerType": 5},
        component: () => import('../views/superdeer-activity/private-invite-202307/receive.vue')
    },
    {
        "path": "/superdeer-activity/private-invite-202307/accept",
        "name": "superdeer-activity/private-invite-202307/accept",
        "meta": { "title": "邀请有礼","headerType": 5},
        component: () => import('../views/superdeer-activity/private-invite-202307/accept.vue')
    },
    {
        "path": "/superdeer-activity/coupon/index/:id",
        "name": "superdeer-activity/coupon/index",
        "meta": { "title": "领券","headerType": 5},
        component: () => import('../views/superdeer-activity/coupon/index.vue')
    },
    {//通用，需传入3参数
        "path": "/superdeer-activity/receive-reward/index",
        "name": "superdeer-activity/receive-reward/index",
        "meta": { "title": "领奖","headerType": 5},
        component: () => import('../views/superdeer-activity/receiveReward/index.vue')
    },
    {
        "path": "/coach-will-survey",
        "name": "coach-will-survey",
        "meta": { "title": "我要做教练","headerType": 5},
        component: () => import('../views/coach-will-survey/index.vue')
    },
    {
      "path": "/superdeer-activity/hrxgl-opening-202405/index",
      "name": "superdeer-activity/hrxgl-opening-202405/index",
      "meta": { "title": "恒荣星光里店，欢迎新朋友","headerType": 5},
      component: () => import('../views/superdeer-activity/hrxgl-opening-202405/invite.vue')
    },
    {
      "path": "/superdeer-activity/bl-opening-202406/index",
      "name": "superdeer-activity/bl-opening-202406/index",
      "meta": { "title": "宝龙城市广场店，欢迎新朋友","headerType": 5},
      component: () => import('../views/superdeer-activity/bl-opening-202406/invite.vue')
    },
    {
        "path": "/superdeer-activity/anniversary-2024/index",
        "name": "superdeer-activity/anniversary-2024/index",
        "meta": { "title": "千万庆生礼 福利派发中","headerType": 5},
        component: () => import('../views/superdeer-activity/anniversary-2024/index.vue')
    },
    {
      "path": "/superdeer-activity/anniversary-2024/koi",
      "name": "superdeer-activity/anniversary-2024/koi",
      "meta": { "title": "超鹿锦鲤","headerType": 4},
      component: () => import('../views/superdeer-activity/anniversary-2024/koi.vue')
    },
    {
        "path": "/superdeer-activity/anniversary-2024/input",
        "name": "superdeer-activity/anniversary-2024/input",
        "meta": { "title": "运营输入祝福","headerType": 4},
        component: () => import('../views/superdeer-activity/anniversary-2024/inputIn.vue')
      },
]
