/*APP公用引入配置*/
const appConfig = {
    version:'20', // 接口版本号
    appVersion:'4.35.10', // app版本号，部分接口判断APP版本
    viewPortAdapt:{
        viewportWidth:750, // 视窗宽度
        viewportHeight: 1334, // 视窗高度
    }
};
export default appConfig;
