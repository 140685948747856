import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import fastclick from "fastclick"
import appConfig from "./config/appConfig";
import "./config/baseUrl";
import requestUtil from "./config/requestUtil"
import {appType} from '@/lib/appMethod';

import animated from 'animate.css'

import Vant from 'vant';
import 'vant/lib/index.css';

import './lib/directive.js'

import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill();

if(process.env.NODE_ENV !== 'production'){
  import('font-awesome/css/font-awesome.css')
}
import mixin from "./mixin/mixin";
import Toast from '@/components/toast/toast';
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);
Vue.config.productionTip = false;
// Vue.prototype.$enParams = encryptBy
// Vue.prototype.$deParams = decryptBy
Vue.prototype.$axios = requestUtil;
Vue.prototype.$appConfig = appConfig;
Vue.prototype.$toast = Toast;
Vue.prototype.mywindow = window;
Vue.prototype.$EventBus = new Vue();
Vue.prototype.appTypeStr = appType();//因为兼容,一般带$
Vue.prototype.$getImgUrl=function(url){
  return url?url.indexOf('http') > -1 ? url : ('http://img.chaolu.com.cn' + url): '';
}
// fastclick.attach(document.body);
Vue.use(require('vue-wechat-title'));
Vue.use(animated);
Vue.use(Vant);


Vue.mixin(mixin);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
(function(){
  var isPageHide = false;
  window.addEventListener('pageshow', function () {
      if (isPageHide) {
          window.location.reload(); //刷新页面
      }
  });
  window.addEventListener('pagehide', function () {
      isPageHide = true;
  });
})()
// window.addEventListener('pageshow', function (event) {
//   if (event.persisted || window.performance && window.performance.navigation.type == 2)
//   {
//       location.reload();
//   }
// },false);
