// 百度统计公用方法
// 百度统计点击方法，虽然label不用，但是还是建议传一下，方便测试核对数据
import {appType} from '@/lib/appMethod.js'
class BDTJMethod{
  constructor() {
    this._type = appType()
    this.nowPage = ''
    this.baiduStatisticsListener()
  }
  // 百度统计点击事件
  baiduStatisticsClick(eventId, label) {
    try {
      switch (this._type) {
        case "and":
          App.baiduEvent(eventId,'无');
          break;
        case "ios":
          window.webkit.messageHandlers.baiduEvent.postMessage(eventId)
          break;
      }
    } catch (e) {
      // console.log('微信或者浏览器', e)
    }
  }
  // 百度统计点击多参数
  baiduStatisticsAttrsClick(eventId, params) {
    try {
      switch (this._type) {
        case "and":
          App.baiduEventAttributes(eventId,'无', JSON.stringify(params))
          break;
        case "ios":
          window.webkit.messageHandlers.baiduEventAttributes.postMessage(JSON.stringify({
            "LogEvent":eventId,
            "params": params,
          }));
          break;
      }
    } catch (e) {
      // console.log('微信或者浏览器', e)
    }
  }
  // 百度统计进入页面
  baiduStatisticsStart(pageName) {
    this.setNowPage(pageName)
    try {
      switch (this._type) {
        case "and":
          App.baiduInWeb(pageName)
          break;
        case "ios":
          window.webkit.messageHandlers.baiduInWeb.postMessage(pageName)
          break;
      }
    } catch (e) {
      // console.log('微信或者浏览器', e)
    }
  }
  // 百度统计退出页面
  baiduStatisticsEnd (pageName) {
    try {
      switch (this._type) {
        case "and":
          App.baiduOutWeb(pageName)
          break;
        case "ios":
          window.webkit.messageHandlers.baiduOutWeb.postMessage(pageName)
          break;
      }
    } catch (e) {
      // console.log('微信或者浏览器', e)
    }
  }
  // 设置当前页pageName
  setNowPage(pageName){
    this.nowPage = pageName
  }
  // 监听页面切入后台的情况
  baiduStatisticsListener(pageName) {
    if (pageName){
      this.setNowPage(pageName)
    }
    document.addEventListener("visibilitychange",  ()=> {
      try {
        if (document.visibilityState == 'visible') {
          this.baiduStatisticsStart(this.nowPage)
        } else if(document.visibilityState == 'hidden') {
          this.baiduStatisticsEnd(this.nowPage)
        }
      } catch (e) {
        // console.log('微信或者浏览器', e)
      }
    });
  }

}

export default  new BDTJMethod()


