import BDTJ from '@/lib/BDTJMethod.js';
import store from '@/store';
export default function afterRouterAction(to, from){
  store.commit('setLeavePath',from.path)
  // 百度埋点
  let startPageName = to.meta.BDTJPageName
  let endPageName = from.meta.BDTJPageName
  if (endPageName){
    BDTJ.baiduStatisticsEnd(endPageName)
  }
  if (startPageName){
    BDTJ.baiduStatisticsStart(startPageName)
  }

}

