<template>
    <div id="app">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive" v-wechat-title="this.$route.meta.title" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" v-wechat-title="this.$route.meta.title" />
        <payPopup></payPopup>
        <!-- 登录提示 -->
        <van-popup v-model="loginPopupShow_" @close="closePopup" position="center" class="app-pop" :closeable="true">
          <div class="sure-title f36 fw6">提示</div>
          <div class="sure-content f28">请先登录，即可开启完整体验</div>
          <div class="sure-sub f32 fw6" @click="loginOut">去登录</div>
        </van-popup>

        <!-- 升级提示 -->
        <van-popup v-model="upgradeShow_" :close-on-click-overlay="true" closeable @close="closePopup" :overlay="true" style="background-color: transparent; width: 84vw">
          <div class="error-box">
            <h4 class="f36 fw6">提示</h4>
            <p class="f28">请升级至最新版本查看</p>
            <div @click="upgrade" class="btn f32 fw6 row-center-center">去升级</div>
          </div>
        </van-popup>
    </div>
</template>

<script>
import { initPage, getParam } from './lib/utils'
import { loginOut, upgrade } from './lib/appMethod'
import payPopup from './components/pay-popup'
import { mapState } from 'vuex'
export default {
    components: { payPopup },
    data() {
        return {
          loginPopupShow_: false,
          upgradeShow_: false,
        }
    },
    computed: {
      ...mapState(['loginPopupShow', 'upgradeShow']),
    },
    watch: {
        $route(to) {
            this.$nextTick(() => {
                const title = to.meta.title || decodeURIComponent(getParam().title)
                if (title) {
                  document.title = title
                }
                initPage(to.meta.headerType)
            })
        },
        loginPopupShow(v) {
          this.loginPopupShow_ = v
        },
        upgradeShow(v) {
          this.upgradeShow_ = v
        }
        // this.$store.commit('setOpenByApp',true);//hack 不应该在这里设置 此项目移植特例
    },
    methods: {
      loginOut,
      upgrade,
      closePopup() {
        this.$store.commit('openLoginPopup', false)
        this.$store.commit('openUpgradePopup', false)
      }
    }
}
</script>
<style lang="less">
@import url("~@/assets/css/base.less");

.app-pop {
    width: 590px;
    border-radius: 12px;
    padding: 50px 40px;
    text-align: center;
    z-index: 999999 !important;
    .sure-title {
      color: #242831;
    }
    .sure-content {
      color: #3C454E;
      line-height: 1.6;
      margin: 36px 0 48px;
    }
    .sure-sub {
      border-radius: 8px;
      background: #FFDE00;
      color: #000;
      line-height: 96px;
    }
  }
  .error-box {
    width: 630px;
    background: #ffffff;
    border-radius: 12px;
    margin: 0 auto;
    padding-bottom: 48px;
    text-align: center;
    h4 {
      padding-top: 58px;
    }
    p {
      margin: 32px auto 56px;
      width: 510px;
      color: #3c454e;
      line-height: 44px;
    }
    .btn {
      margin: 0 auto;
      width: 510px;
      height: 96px;
      background: #ffde00;
      border-radius: 8px;
    }
  }
</style>
