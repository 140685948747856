import Vue from 'vue';
import store from '@/store';
import VueRouter from 'vue-router';
import routes from './routerList';
import routerLxh from "./router_lxh";
import routerJjr from "./router_jjr";
import routerLY from "./router_ly";
import routerWXG from "./router_wxg";
import routerIpad from "./router_ipad";
import beforeRouterAction from '@/lib/beforeRouer';
import afterRouterAction from '@/lib/afterRouer';
import {defaultApp} from '@/lib/appMethod';
Vue.use(VueRouter);

const router = new VueRouter({
  // hashbang: true,
  history: false, //这个参数改为false就可以了
  saveScrollPosition: true,
  suppressTransitionError: true,
  routes:[...routes,...routerLxh,...routerJjr,...routerWXG,...routerLY,...routerIpad]
})
router.beforeEach((to, from, next) => {
  beforeRouterAction(to, from, next)
})
router.afterEach((to, from) => {
  afterRouterAction(to, from);
})
router.onReady((data)=>{
  // Toast(window.history.length);
  if(window.history.length>1){
    if(window.history.state?.firstPage){
      store.commit('setOpenByApp',true);
    }else{
      store.commit('setOpenByApp',false);
    }
  }else{
    window.history.replaceState({firstPage:1},'',location.href);
    store.commit('setOpenByApp',true);
  }
})
router.onError(()=>{
  defaultApp();
})

export default router
