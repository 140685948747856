export default [
    {
        "path": "/ipad/mall",
        "name": "ipad-mall",
        "meta": {"title": "卡券商城", "headerType": 4},
        component: () => import('../views/ipad/mall.vue')
    },
    {
        "path": "/ipad/case-lib",
        "name": "ipad-case",
        "meta": {"title": "超鹿案例库", "headerType": 4},
        component: () => import('../views/ipad/case-lib.vue')
    },
    {
      "path": "/ipad/user-album",
      "name": "user-album",
      "meta": {"title": "会员相册", "headerType": 4},
      component: () => import('../views/ipad/user-album.vue')
    },
    {
        "path": "/ipad/project",
        "name": "ipad-project",
        "meta": {"title": "运动方案", "headerType": 4},
        component: () => import('../views/ipad/project.vue')
    },
    {
        "path": "/ipad/my-project",
        "name": "ipad-my-project",
        "meta": {"title": "运动方案", "headerType": 4},
        component: () => import('../views/ipad/my-project.vue')
    },
]
