import Vue from 'vue'

Vue.directive('loadmore', {
  //
  inserted: (el, bind, vnode) => {
    // el当前绑定的元素
    // 给元素添加滚动事件监听
    el.addEventListener('scroll', function () {
      if ((el.scrollHeight - el.clientHeight) - el.scrollTop < 50) {
        // binding.value来调用指令上的方法获取数据
        bind.value()
      }
    })
  }
})
